.section-title::after {
  content: "";
  height: 3px;
  background: #fbb900;
  width: 80px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
.setcard-background{
  background-color: rgb(255, 255, 255) !important;
}
.setBG-card{

  background-image: url("../../public/img/futuristic-illustration-person-with-virtual-reality-glasses-elements-background_7023-429884-removebg-preview.png");
  /* background-position: 0px 0px; */
  background-repeat: no-repeat;
}
.setExpertCard{
  min-height: 250px;
  max-height: 320px;
  min-width: 250px;
  /* max-width: 300px; */
  /* box-shadow: 0px 0px 20px rgb(213, 213, 213) !important; */
  background: linear-gradient(#29f6179f, #014313);
  transition: all 0.4s cubic-bezier(0, -0.04, 0, 0.99);
  color: #ffffff;
  margin-top: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif ;
  /* border-radius: 30px; */
  /* font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif */
  /* border-top:2px solid rgb(215, 255, 247) ; */

}
.setExpertCard:hover{
transform: scale(1.1);
border-radius: 10px;
/* border-block: 2px solid rgb(7, 1, 1); */
}

.setExpertCard h6{
  color:#0b4d05;
  font-weight:bolder

}
  


/****************/
/*	 BX-SLIDER 	*/
/****************/
section.client {
  padding: 4em 0em;
  background-color: #ffffff;
}
section.client .section-title {
  margin-bottom: 6em;
}
.bx-controls {
  position: relative;
}
.bx-wrapper .bx-pager {
  text-align: center;
  padding-top: 30px;
}
.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  /* display: inline-block;
      *zoom: 1;
      *display: inline; */
}
.bx-wrapper .bx-pager.bx-default-pager a {
  background: #fffdfd;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.setImg {
  width: 200px;
  margin: 50px;
  /* border: 1px solid red; */
}
.carousel-client {
  align-items: center;
}
.setH {
  font-family: "Arial Narrow Bold", sans-serif;
  text-transform: capitalize;
  color: rgb(3, 74, 74);
}

.setbody {
  height: 70vh;
  display: flex;
  justify-content: center;
  background: rgb(0, 0, 0);
  overflow-x: hidden;
  align-items: center;
}
button {
  --glow-color: #26bd19;
  --glow-spread-color: #1ed50d;
  --enhanced-glow-color: #26bd19;
  --btn-color: #16820d;
  border: 0.25em solid var(--glow-color);
  padding: 1em 3em;
  color: var(--glow-color);
  font-size: 15px;
  font-weight: bold;
  color: #cdcdcd;

  background-color: var(--btn-color);
  border-radius: 1em;
  outline: none;
  box-shadow: 0 0 1em 0.25em var(--glow-color),
    0 0 4em 1em var(--glow-spread-color),
    inset 0 0 0.75em 0.25em var(--glow-color);
  text-shadow: 0 0 0.5em var(--glow-color);
  position: relative;
  transition: all 0.3s;
}

button::after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 120%;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--glow-spread-color);
  filter: blur(2em);
  opacity: 0.7;
  transform: perspective(1.5em) rotateX(35deg) scale(1, 0.6);
}

button:hover {
  color: white;
  background-color: var(--glow-color);
  box-shadow: 0 0 1em 0.25em var(--glow-color),
    0 0 4em 2em var(--glow-spread-color),
    inset 0 0 0.75em 0.25em var(--glow-color);
}

button:active {
  box-shadow: 0 0 0.6em 0.25em var(--glow-color),
    0 0 2.5em 2em var(--glow-spread-color),
    inset 0 0 0.5em 0.25em var(--glow-color);
}
.text{
    color:#26bd19;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif ;
}
.text-sub{
    color: rgb(0, 0, 0);
    font-family: 'Times New Roman', Times, serif;
}
.setCard{
    box-shadow: 1px 1px 24px  rgb(116, 118, 117);
    background-color: rgb(245, 245, 245);
    margin: 30px;
}
.bgIMH{
    background-image: url("../../public/img/play.png");
    background-position: top left;
    background-size: 500px;
    background-repeat: no-repeat;
}
@media only screen and (max-width: 650px) {
    .bgIMH{
      background-image:none;
    }
      
  }
  
/* src/components/Loader.css */
.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8); /* semi-transparent background */
    z-index: 1000;
  }
  
  .spinner {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .textt {
    position: absolute;
    top: 50%; 
    right: 50%;
    transform: translate(50%,-50%);
    text-transform: uppercase;
    font-family: verdana;
    font-size: 37px;
    font-weight: 700;
    color: #19e15f;
    text-shadow: 1px 1px 1px #36c711,
        1px 2px 1px #36c711,
        1px 3px 1px #434243,
        1px 4px 1px #434243,
        1px 5px 1px #434243,
        1px 6px 1px #434243,
        1px 7px 1px #434243,
        1px 8px 1px #434243,
        1px 9px 1px #434243,
        1px 10px 1px #434243,
    1px 18px 6px rgba(16,16,16,0.4),
    1px 22px 10px rgba(16,16,16,0.2),
    1px 25px 35px rgba(16,16,16,0.2),
    1px 30px 60px rgba(16,16,16,0.4);
}
.setHeaderBg{
    background-color: #f6f4f4;
    /* background-color: transparent; */

 
}
.setDCC{ 
    font-family: "Courier New";
}
.setli{
    list-style: none;
    margin-inline:40px;
    font-weight: 600;
    font-size: 20px;
    color: rgb(242, 237, 237);
    align-items: center;
    cursor: pointer;
}
.setliSmall{
    list-style: none;
    /* margin-inline:20px; */
    font-weight: 500;
    font-size: 20px;
    color: rgb(255, 255, 255);
    align-items: center;
    cursor: pointer;  
    font-family: Arial;
    /* border-bottom: 1px solid rgb(92, 237, 102); */
}

.setLogo{
    object-fit: fill;
        object-position: -50px -50px;
}
  

@media only screen and (max-width: 1100px) {
    .disNone{
        display: none;
    }
    .mobileViewBuuton{
        display: flex;
    }
  }
  @media only screen and (min-width: 1100px) {
  
    .mobileViewBuuton{
        display: none;
    }
  }
  .setPostion{
    position: absolute;
    right: 40px;
    
  }

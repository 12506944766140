.setSpace {
  /* width: 100vw; */
  height: 93vh;
  /* background-image:linear-gradient(to bottom , rgb(139, 46, 133),rgb(5, 24, 192)); */
  background-image: url("../../public/img/kd.jpg");
  background-size: 100vw 100vh;
}

.settypeWriter {
  /* left: 10%;
    top: 30%; */
  position: relative;
  top: 30%;
  font-family: sans-serif;
  font-size: 70px;
  font-style: normal;
  font-weight: 600;
  color: whitesmoke;
}


@keyframes rotate {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 530px 0;
  }
}

.set {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: red; */
  position: relative;
  top: 33vh;
  
}

.frame {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.btnn {
  height: 47px;
  width: 47px;
  border-radius: 3px;
  background: #dadada;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.3),
    7px 7px 20px 0px rgba(0, 0, 0, 0.2), 4px 4px 5px 0px rgba(0, 0, 0, 0.614);
  transition: box-shadow 0.6s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  font-size: 17px;
  color: #097705;
  text-decoration: none;
  margin: 20px;
}
.btnn:hover {
  color: #fefeff;
}
.btnn:active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 650px) {
  .setSpace {
    background-image: linear-gradient(#063f4d, #4ecc6d);
    background-size: 100vw 100vh;
  }

  #myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }

  .content {
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    padding: 20px;
  }
  .settypeWriter {
    position: relative;
    top: 20%;
    font-family: "cooper";
    font-size: 30px;
    font-style: normal;
    font-weight: 1500;
    color: #f6f6ff;
  }
  .gradient-text {
    background: linear-gradient(to right, #3effff, #f722c2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .btnn {
    height: 47px;
    width: 47px;
    border-radius: 3px;
    background: #d8d9d8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    /* box-shadow:
     inset 2px 2px 2px 0px rgba(255,255,255,.3),
     7px 7px 20px 0px rgba(0,0,0,.2),
     4px 4px 5px 0px rgba(163, 27, 92, 0.902);
   transition:box-shadow 0.6s cubic-bezier(.79,.21,.06,.81); */
    font-size: 17px;
    color: #07691c;
    text-decoration: none;
    margin: 3px;
  }
  .set{
    left: 10vw;
  }


  .frame {
    /* display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    box-shadow: inset 2px 2px 2px 0px rgba(14, 7, 7, 0.3),
      7px 7px 20px 0px rgba(18, 9, 115, 0.2), 4px 4px 5px 0px rgba(208, 207, 221, 0.917);
    transition: box-shadow 0.6s cubic-bezier(0.79, 0.21, 0.06, 0.81);
    border-radius: 10px; */
  }
}

@media only screen and (min-width: 650px) {
  .setHider {
    display: none;
  }
  .setter {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    /* Full viewport height */
  }
  .setSpace {
    background-color: #000000 !important;
  }
  .setTab {
    font-size: 72px;
    color: #28d754;
    font-weight: bolder;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000; /* Outline color */
  }

  .setIMM {
    display: none;
  }

 
  .setAppro {
    color: white !important;
  }
  .IMGLOGO {
    display: none;
  }
}

@media only screen and (max-width: 650px) {
  .set {
    /* display: none; */
    position: absolute;
    top: 50vh;
    /* right: 20vw; */
  }

  .setColor {
    color: #34e15a;
    font-size: 30px !important ;
    font-weight: 600;
  }
  .setTab {
    font-size: 37px;
    color: #38ef63;
    font-weight: bold;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000; /* Outline color */
  }
  .setIMM {
    width: 100%;
  }
  .setHeaderBg {
    display: none;
  }

  .setAppro {
    color: #f8f8f8 !important;
    margin-top: 100px;
    font-size: 20px !important;
  }
  .IMGLOGO {
    margin-block: 30px;
  }
}

/* // black background */

.setBackGround {

  background-color: #ffffff;
  height: 100vh;


  background-repeat: no-repeat;
}
.SetMainText {
  color: rgb(38, 189, 25);
  font-size: 67px;
  font-family: Arial Impact,"Arial Narrow Bold", sans-serif;
  text-shadow: 2px 2px #10960b;
  text-transform: uppercase;
}

.setINMGG {
  max-height: 30%;
}
.seg {
  position: relative;
  top:21%;
}


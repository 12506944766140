.setbg_vd{
    background-color:#020a0a ;
    color: white;
    
}
.setbg_vd h2{
 
    color:#03de41   ;
    
}

.navigation {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuToggle {
    position: relative;
    width: 60px;
    height: 60px;
    background-color: #fff;
    border-radius: 70px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
}

.menuToggle::before {
    content: '+';
    position: absolute;
    font-size: 2.8em;
    font-weight: 550;
    color: #087fe0;
    transition: 1.5s;
}

.menuToggle.active::before {
    transform: rotate(315deg);
}

.menu {
    position: absolute;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 70px;
    z-index: -1;
    transition: transform 0.5s, width 0.5s, height 0.5s;
    transition-delay: 1s, 0.5s, 0.5s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.menuToggle.active~.menu {
    width: 270px;
    height: 70px;
    z-index: 1;
    transform: translateY(-80px);
    transition-delay: 0s, 0.5s, 0.5s;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
}

.menu::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background: #fff;
    left: calc(50% - 8px);
    bottom: 4px;
    transform: rotate(45deg);
    border-radius: 2px;
    transition: 0.5s;
}

.menuToggle.active~.menu::before {
    transition-delay: 0.5s;
    bottom: -6px;
}

.menu ul {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    gap: 30px;
}

.menu ul li {
    list-style: none;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-30px);
    transition: 0.25s;
    transition-delay: calc(0s + var(--i));
}

.menuToggle.active~.menu ul li {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition-delay: calc(0.75s + var(--i));
}

.menu ul li a {
    display: block;
    font-size: 2.2em;
}

.btnnn {
    width: 90px;
    height: 90px;
    margin: 10px;
    color: #14c647;
    overflow: hidden;
    position: relative;
    border-radius: 30%;
    background: #f1f1f1;
    text-decoration: none;
    box-shadow: 0 5px 15px -5px #00000070;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 26px;
      transition: 0.2s linear;
    }
    &:before {
      content: "";
      width: 120%;
      height: 120%;
      left: -110%;
      top: 90%;
      position: absolute;
      background: #3498db;
      transform: rotate(45deg);
      background-image: radial-gradient(circle farthest-corner at 10% 20%, #d41dde 0%, #3498db 100%);
    }
    &:hover {
      i {
        color: #f1f1f1;
        transform: scale(1.3);
      }
      &:before {
        top: -10%;
        left: -10%;
        animation: animation1 0.7s 1;
      }
    }
  }
  
  @keyframes animation1 {
    0% {
      top: 90%;
      left: -110%;
    }
  
    50% {
      top: -30%;
      left: 10%;
    }
  
    100% {
      top: -10%;
      left: -10%;
    }
  }


  .center-items {
    display: flex;
    align-items: center;
    justify-content: center;
  }
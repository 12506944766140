@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");



section {
    display: grid;
    place-items: center;
}


.slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 80%;
    overflow: hidden;
    /* background: rgb(165, 165, 165); */
    border-radius: 16px;
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(7.4px);
    -webkit-backdrop-filter: blur(7.4px);
    border: 1px solid rgba(255, 255, 255, 0.4);
}

.slider-items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    animation: scrolling 20s linear infinite;

}

@keyframes scrolling {

    0% {
        transform: translateX(80%);
    }

    100% {
        transform: translateX(-20%);
    }
}

.slider-items img {
    width: auto;
    height: 50px;
    margin: 20px;

}